//const v1="MESSAGE_VAR";
export const notEmpty = "Favor de ingresar una pregunta en la caja de texto.";
export const speranding = "Estoy obteniendo tu respuesta...";
export const msgVolverPreguntarButton = "Volver a preguntar";
export const v1 = "Puedes escribir tu pregunta o grabar tu pregunta por voz así:\n 1. Haz click en grabar,\n 2. Realiza tu pregunta con voz,\n 3. Luego, haz click en Detener, y\n 4. Haz click en Preguntar";
//"Hola soy ASTRID, ingresa una pregunta en la caja de texto, para interactuar conmigo. Por ejemplo, ¿cuáles son los beneficios del seguro de gastos médico menores?";
export const errorGeneral = "Hubo un problema al intentar obtener tu respuesta. Inténtalo más tarde";
export const error4xxMessage = "Hubo un error de acceso, favor de ingresar las credenciales nuevamente";
export const error5xxMessage = "Error en el servidor, favor de recargar la página";
export const msg4xxError = "ERR_BAD_REQUEST";
export const param1 = "ASTRID";
export const mgnStartTranscription = "Grabar";
export const mgnStopTranscription = "Detener transcripción";
export const mgnClearTranscription = "Limpiar transcripción";
export const mgnIntroTranscription = "";
//"Ahora puedes grabar tu pregunta por voz. Haz click en grabar, haz tu pregunta con voz, haz click detener, y luego haz click en preguntar";