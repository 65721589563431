const { cognitoIdentityPoolId } = require("../../demo-credentials.js");
const {
    mgnStartTranscription, mgnStopTranscription, mgnClearTranscription, mgnIntroTranscription, v1
} = require('../../messages-es-mx.js');

const audioUtils = require('./audioUtils');  // for encoding audio data as PCM
const crypto = require('crypto'); // tot sign our pre-signed URL
const v4 = require('./aws-signature-v4'); // to generate our pre-signed URL
const marshaller = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
const mic = require('microphone-stream'); // collect microphone input as a stream of raw bytes

// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);

// our global variables for managing state
const languageCode = "es-US";
const sampleRate = 44100;
const region = cognitoIdentityPoolId.split(":")[0];

let recording_state = "not-recording";

let inputSampleRate;
let transcription = "";
let socket;
let micStream;
let socketError = false;
let transcribeException = false;

// Set the region where your identity pool exists (us-east-1, eu-west-2)
AWS.config.region = region;

// Configure the credentials provider to use your identity pool
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: cognitoIdentityPoolId,
});

// Make the call to obtain credentials
AWS.config.credentials.get(function () {

    // Credentials will be available when this function is called.
    var accessKeyId = AWS.config.credentials.accessKeyId;
    var secretAccessKey = AWS.config.credentials.secretAccessKey;
    var sessionToken = AWS.config.credentials.sessionToken;

});

// check to see if the browser allows mic access
if (!window.navigator.mediaDevices.getUserMedia) {
    // Use our helper method to show an error on the page
    showError('We support the latest versions of Chrome, Firefox, Safari, and Edge. Update your browser and try your request again.');

    // maintain enabled/distabled state for the start and stop buttons
    toggleStartStop();
}

// buttons and head inital state
$(document).ready(function () {
    $('#intro-transcription').text(mgnIntroTranscription);
    $('#start-button').html('<i class="fa fa-microphone"></i> ' + mgnStartTranscription);
    $('#stop-button').html('<i class="fa fa-stop-circle"></i> ' + mgnStopTranscription);
    $('#reset-button').text(mgnClearTranscription);
    $('#stop-button').hide();
    $('#speechText').attr("placeholder", v1);
    // Get the button with id "speakButton"
    var speakButton = $('#speakButton');
    // Store the original onClick functionality
    var originalOnClick = speakButton.prop('onclick');

    // Overwrite the onClick functionality
    speakButton.prop('onclick', null).off('click').on('click', function () {
        //stop recording when hitting ask questing
        if (recording_state === "recording") {
            recording_state = "not-recording";
            closeSocket();
            toggleStartStop(recording_state);
        }

        // Call the original onClick functionality (if it exists)
        if (originalOnClick) {
            originalOnClick.call(this);
        }

        // Set the variable "transcription" to an empty string
        transcription = "";
    });
});

$('#start-button').click(function () {
    $('#error').hide(); // hide any existing errors
    
    if (!$('#speechText').val()){
        transcription = "";    
    }
        
    //make a 3 second timer and show the seconds in the start button make the browser wait for 3 seconds
    if (recording_state === "not-recording") {
        let microphone_promise = window.navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true
        });
        var timeleft = 3;
        var downloadTimer = setInterval(function () {
            if (timeleft <= 0) {
                transcription =$('#speechText').val();
                recording_state = "recording";
                clearInterval(downloadTimer);
                toggleStartStop(recording_state)

                    // ...then we convert the mic stream to binary event stream messages when the promise resolves 
                    microphone_promise.then(streamAudioToWebSocket)
                    .catch(function (error) {
                        showError('There was an error streaming your audio to Amazon Transcribe. Please try again.');
                        toggleStartStop();
                    });
            } else {
                document.getElementById("start-button").innerHTML = '<i class="fa fa-microphone"></i> ' + `Comienza en ${timeleft}`;
            }
            timeleft -= 1;
        }, 1000);
    } else if (recording_state === "recording") {
        recording_state = "not-recording";
        closeSocket();
        toggleStartStop(recording_state);
    }

});

let streamAudioToWebSocket = function (userMediaStream) {
    //let's get the mic input from the browser, via the microphone-stream module
    micStream = new mic();

    micStream.on("format", function (data) {
        inputSampleRate = data.sampleRate;
    });

    micStream.setStream(userMediaStream);

    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = createPresignedUrl();

    //open up our WebSocket connection
    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    let sampleRate = 0;

    // when we get audio data from the mic, send it to the WebSocket if possible
    socket.onopen = function () {
        micStream.on('data', function (rawAudioChunk) {
            // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
            let binary = convertAudioToBinaryMessage(rawAudioChunk);

            if (socket.readyState === socket.OPEN)
                socket.send(binary);
        }
        )
    };

    // handle messages, errors, and close events
    wireSocketEvents();
}

function wireSocketEvents() {
    // handle inbound messages from Amazon Transcribe
    socket.onmessage = function (message) {
        //convert the binary event stream message to JSON
        let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
        let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
        if (messageWrapper.headers[":message-type"].value === "event") {
            handleEventStreamMessage(messageBody);
        }
        else {
            transcribeException = true;
            showError(messageBody.Message);
            toggleStartStop();
        }
    };

    socket.onerror = function () {
        socketError = true;
        showError('WebSocket connection error. Try again.');
        toggleStartStop();
    };

    socket.onclose = function (closeEvent) {
        micStream.stop();

        // the close event immediately follows the error event; only handle one.
        if (!socketError && !transcribeException) {
            if (closeEvent.code != 1000) {
                showError('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
            }
            toggleStartStop();
        }
    };
}

let handleEventStreamMessage = function (messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;

            // fix encoding for accented characters
            transcript = decodeURIComponent(escape(transcript));

            // update the textarea with the latest result
            $('#speechText').val(transcription + transcript + "\n");

            // if this transcript segment is final, add it to the overall transcription
            if (!results[0].IsPartial) {
                //scroll the textarea down
                $('#speechText').scrollTop($('#speechText')[0].scrollHeight);

                transcription += transcript + "\n";
            }
        }
    }
}

let closeSocket = function () {
    if (socket.readyState === socket.OPEN) {
        micStream.stop();

        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
        let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
        socket.send(emptyBuffer);
    }
}

$('#stop-button').click(function () {
    closeSocket();
    toggleStartStop();
});

$('#reset-button').click(function () {
    $('#speechText').val('');
    transcription = '';
});


//TODO machine state 
function toggleStartStop(state) {
    if (state === "recording") {
        $('#start-button').text("Detener");
    } else if (state === "not-recording") {
        $('#start-button').text("Grabar");
    }
}

function showError(message) {
    $('#error').html('<i class="fa fa-times-circle"></i> ' + message);
    $('#error').show();
}

function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);

    if (raw == null)
        return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, inputSampleRate, sampleRate);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
}

function getAudioEventMessage(buffer) {
    // wrap the audio data in a JSON envelope
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
}

function createPresignedUrl() {
    let endpoint = "transcribestreaming." + region + ".amazonaws.com:8443";

    // get a preauthenticated URL that we can use to establish our WebSocket
    return v4.createPresignedURL(
        'GET',
        endpoint,
        '/stream-transcription-websocket',
        'transcribe',
        crypto.createHash('sha256').update('', 'utf8').digest('hex'), {
        'key': AWS.config.credentials.accessKeyId,
        'secret': AWS.config.credentials.secretAccessKey,
        'sessionToken': AWS.config.credentials.sessionToken,
        'protocol': 'wss',
        'expires': 15,
        'region': region,
        'query': "language-code=" + languageCode + "&media-encoding=pcm&sample-rate=" + sampleRate
    }
    );
}
