// TODO: Update the value below to match a Cognito Identity Pool created in your
// AWS account. The unauthenticated IAM role for the pool (usually ending in the
// suffix "Unauth_Role") must have the following managed permissions policies
// assigned to it:
//   - AmazonPollyReadOnlyAccess

export const cognitoIdentityPoolId = 'us-west-2:6cddf66b-8cdf-43b9-b7c9-41c6d51e2f12'; // 👈 replace this value with your cognito identity pool Id!
export const apiEndPoint =  'https://enwe79hkk1.execute-api.us-west-2.amazonaws.com/Prod/chat'; // 👈 replace this value for sagemaker endpoint!
//export const cognitoIdentityPoolId = "COGNITO_IDENTITY_POOLID";
//export const apiEndPoint = "API_ENDPOINT";
export const cognitoUserPool = 'us-west-2_hnjuEXe4R';
export const cognitoClientID = '39767ni7q8r7ngb180cgm96f7r';